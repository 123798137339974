import { useEffect, useState } from "react";
import { Form, Input, Button, Space, Alert, Radio, message, Modal } from 'antd'
import { useTranslation } from "react-i18next";
import { PublicKey, Connection, clusterApiUrl, TransactionMessage, VersionedTransaction, ComputeBudgetProgram } from '@solana/web3.js'
import { getAssociatedTokenAddress, createBurnCheckedInstruction } from '@solana/spl-token'
import { useSelector } from 'react-redux';
import { AnchorProvider, BN } from '@project-serum/anchor'
import { LoadingOutlined } from '@ant-design/icons'

function BurnTokenPage() {
    const [messageApi, contextHolder] = message.useMessage()
    const [burnInfo, setBurnInfo] = useState({ mintAddress: '', amount: '' })
    const { t } = useTranslation()
    const [mintInfo, setMintInfo] = useState({
        amount: '',
        decimals: 0,
        uiAmount: '',
        balance: ''
    })
    const [getMintInfoLoading, setGetMintInfoLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [errMessage, setErrMessage] = useState('')

    const [submitStatus, setSubmitStatus] = useState(false)  // 创建是否成功, 如果成功了要给提示
    const [submitTxHash, setSubmitTxHash] = useState('')  // 创建成功后的交易哈希
    const [currentNetwork, setCurrentNetwork] = useState('mainnet-beta') // 选择当前网络
    const [burnType, setBurnType] = useState('token') // 选择销毁类型, token or lp
    const [showFindLpTokenDialog, setShowFindLpTokenDialog] = useState(false) // 是否显示如何查找LP代币的对话框
    const walletAddress = useSelector(state => state.network.wallet)

    const getProvider = () => {
        const connection = getConnection()
        const provider = new AnchorProvider(connection, window.solana, {
            preflightCommitment: 'confirmed',
            commitment: 'confirmed',
        })
        return provider
    }

    const submit = async () => {
        if (!isValidSolanaAddress(burnInfo.mintAddress)) {
            messageApi.error(t('无效的mint地址'))
            return
        }
        if (burnInfo.amount === '') {
            messageApi.error(t('请输入销毁数量'))
            return
        }
        if (mintInfo.balance === '') {
            messageApi.error(t('还未获取到代币信息, 请稍后再试'))
            return
        }
        const connection = getConnection()
        const mint = new PublicKey(burnInfo.mintAddress)
        const wallet = new PublicKey(walletAddress)
        const ata = await getAssociatedTokenAddress(mint, wallet)
        setErrMessage('')
        setSubmitLoading(true)
        try {
            const provider = getProvider()
            const burnIx = createBurnCheckedInstruction(
                ata, // PublicKey of Owner's Associated Token Account
                mint, // Public Key of the Token Mint Address
                wallet, // Public Key of Owner's Wallet
                burnInfo.amount * (10 ** mintInfo.decimals), // Number of tokens to burn
                // new BN(100000),
                mintInfo.decimals // Number of Decimals of the Token Mint
            );

            const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash('finalized');


            const t1 = ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: 50000
            })
            const t2 = ComputeBudgetProgram.setComputeUnitLimit({
                units: 1000000
            })

            const messageV0 = new TransactionMessage({
                payerKey: wallet,
                recentBlockhash: blockhash,
                instructions: [burnIx, t1, t2]
            }).compileToV0Message();
            const transaction = new VersionedTransaction(messageV0);
            console.log(`    ✅ - Transaction Created and Signed`);
            console.log(`Step 5 - Execute & Confirm Transaction`);
            const txid = await provider.sendAndConfirm(transaction)
            console.log("    ✅ - Transaction sent to network");
            const confirmation = await connection.confirmTransaction({
                signature: txid,
                blockhash: blockhash,
                lastValidBlockHeight: lastValidBlockHeight
            });
            // if (confirmation.value.err) { throw new Error("    ❌ - Transaction not confirmed.") }
            // console.log('🔥 SUCCESSFUL BURN!🔥', '\n', `https://explorer.solana.com/tx/${txid}?cluster=devnet`);
            // 已销毁, 展示销毁信息
            setSubmitStatus(true)
            setSubmitTxHash(txid)

            messageApi.success(t('销毁成功'))
        } catch (e) {
            console.log('销毁失败', e)
            setErrMessage(e.message)
        }
        setSubmitLoading(false)



    }

    const isValidSolanaAddress = (address) => {
        try {
            // 尝试创建一个PublicKey实例，如果地址无效，将抛出错误
            new PublicKey(address);
            return true;
        } catch (error) {
            // 如果出现错误，地址无效
            return false;
        }
    }

    const getConnection = () => {
        let url = 'https://dry-sparkling-road.solana-mainnet.quiknode.pro/9155fd5ad8287d2247fb4cde578fbf9ce2c42f1b/'
        if (currentNetwork === 'devnet') {
            url = clusterApiUrl(currentNetwork)
        }
        const connection = new Connection(url);
        return connection
    }

    const getMintTokenInfo = async (address) => {
        // const splToken = require('@solana/spl-token')
        // 获取mint的信息
        setGetMintInfoLoading(true)
        setErrMessage('')
        try {
            const mintPublicKey = new PublicKey(address)
            const connection = getConnection()

            const info = await connection.getTokenSupply(mintPublicKey)

            const { amount, decimals, uiAmount } = info.value

            const ataWallet = await getAssociatedTokenAddress(mintPublicKey, new PublicKey(walletAddress))
            const balance = await connection.getTokenAccountBalance(ataWallet)
            const b = balance.value.uiAmountString


            setMintInfo({
                amount,
                decimals,
                uiAmount,
                balance: b
            })
        } catch (e) {
            console.log(e)
            setErrMessage(e.message)
        }
        setGetMintInfoLoading(false)

    }

    const showTx = () => {
        const url = `https://solscan.io/tx/${submitTxHash}${currentNetwork === 'devnet' ? '?cluster=devnet' : ''}`
        window.open(url, '_blank')
    }

    useEffect(() => {
        // 当是合法的mint地址时，显示mint的信息
        if (isValidSolanaAddress(burnInfo.mintAddress)) {
            console.log('合法地址, 尝试获取代币信息')
            getMintTokenInfo(burnInfo.mintAddress)
        }
    }, [burnInfo.mintAddress, currentNetwork])

    return <>
        {contextHolder}
        <div>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} >
                <Form.Item label={t('选择网络')}>
                    <Radio.Group onChange={e => {
                        setCurrentNetwork(e.target.value)
                    }} value={currentNetwork}>
                        <Radio value={'mainnet-beta'}>{t('Solana主网')}</Radio>
                        <Radio value={'devnet'}>{t('Solana开发网')}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={t('销毁代币类型')}>
                    <Radio.Group onChange={e => {
                        setBurnType(e.target.value)
                    }} value={burnType}>
                        <Radio value={'token'}>{t('普通SPL代币')}</Radio>
                        <Radio value={'lp'}>{t('池子LP代币')}</Radio>
                    </Radio.Group>
                </Form.Item>
                {/* 输入要销毁的代币地址 输入销毁数量 确认 */}
                <Form.Item label={t(burnType === 'token' ? '代币地址' : 'LP代币地址')}>
                    <Input value={burnInfo.mintAddress} onChange={e => {
                        setBurnInfo({ ...burnInfo, mintAddress: e.target.value })
                    }} />
                    {burnType === 'lp' && <div onClick={e => {
                        setShowFindLpTokenDialog(true)
                    }} style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', color: 'rgb(22, 119, 255)', cursor: 'pointer', marginTop: '5px' }}>
                        {t('不知如何查找LP代币地址?')}
                    </div>}
                </Form.Item>
                {getMintInfoLoading ? <Form.Item wrapperCol={{ offset: 4, span: 14 }} label="">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <LoadingOutlined />
                        <div style={{ marginTop: '10px' }}>
                            {t('正在获取代币信息...')}
                        </div>

                    </div>

                </Form.Item> : <>
                    {mintInfo.decimals > 0 && <Form.Item label={t('精度')}>
                        <Input value={mintInfo.decimals} disabled />
                    </Form.Item>}
                    {mintInfo.uiAmount > 0 && <Form.Item label={t('总量')}>
                        <Input value={mintInfo.uiAmount} disabled />
                    </Form.Item>}
                    {mintInfo.balance > 0 && <Form.Item label={t('可用余额')}>
                        <Input value={mintInfo.balance} disabled />
                    </Form.Item>}
                </>}


                <Form.Item label={t('销毁数量')}>
                    <Space.Compact style={{ width: '100%' }}>
                        <Input style={{ width: '100%' }} value={burnInfo.amount} onChange={e => {
                            setBurnInfo({ ...burnInfo, amount: e.target.value })
                        }} />
                        <Button onClick={e => {
                            setBurnInfo({ ...burnInfo, amount: mintInfo.balance })
                        }}>{t('最大')}</Button>
                    </Space.Compact>

                </Form.Item>



                {errMessage && <>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Alert
                            style={{ width: '100%' }}
                            message={t('错误')}
                            description={errMessage}
                            type="error"
                            closable={false}
                        />
                    </Form.Item>
                </>}

                {submitStatus && <Form.Item wrapperCol={{ span: 24 }}>
                    <Alert
                        style={{ width: '100%' }}
                        message={t('销毁成功')}
                        type="success"
                        closable={false}
                    />
                </Form.Item>}


                <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
                    <Button loading={submitLoading} onClick={submit} type='primary'>{t('确认销毁')}</Button>
                    {submitTxHash && <Button style={{ marginLeft: '10px' }} onClick={showTx} type='primary'>{t('查看交易哈希详情')}</Button>}
                </Form.Item>
            </Form>

            <Modal title={t('如何查找LP代币?')} open={showFindLpTokenDialog} onOk={() => {
                setShowFindLpTokenDialog(false)
            }} onCancel={() => {
                setShowFindLpTokenDialog(false)
            }} footer={null} >
                <div>
                    1. {t('在1')} <a target="_blank" href="https://dextools.io/app"> {t('Dextools[点此打开]')} </a> {t('中搜索您的代币')}

                    <img style={{ width: '100%', marginTop: '5px' }} src="https://token-monitor.s3.amazonaws.com/1710262204837screenshot-20240313-004919%20%281%29.png" alt="" />
                    <br />
                    2. {t('在代币详情页面中点击Pair旁边的复制按钮, 以复制Pair地址')}
                    <img style={{ width: '100%', marginTop: '5px' }} src="https://token-monitor.s3.amazonaws.com/1710262285444screenshot-20240313-005106%20%281%29.png" alt="" />
                    <br />
                    3. {t('在2')} <a target="_blank" href="https://solscan.io/">{t('Solana区块浏览器[点此打开]')}</a> {t('中搜索这个地址')}

                    <img style={{ width: '100%', marginTop: '5px' }} src="https://token-monitor.s3.amazonaws.com/1710262345829screenshot-20240313-005212%20%281%29.png" alt="" />
                    <br />
                    4. {t('在搜索结果中找到对应的mint地址就是LP代币地址, 点击旁边的复制按钮, 将mint地址复制到输入框中即可')}
                    <img style={{ width: '100%', marginTop: '5px' }} src="https://token-monitor.s3.amazonaws.com/1710262434133screenshot-20240313-005336%20%281%29.png" alt="" />
                    <br />

                    <Button style={{ marginTop: '10px' }} onClick={e => {
                        setShowFindLpTokenDialog(false)
                    }} type="primary" danger>{t('关闭')}</Button>
                </div>
            </Modal>
        </div>
    </>
}

export default BurnTokenPage;