import { useState } from "react";
import { Upload, message, Button, Modal, Form, Input } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { getIpfsUrl, uploadFile } from "../../utils/ipfs";
import { useTranslation } from "react-i18next";

function UploadImage(props) {
    const [messageApi, contextHolder] = message.useMessage()

    const [imageUrl, setImageUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [showUploadUrlDialog, setShowUploadUrlDialog] = useState(false)
    const [tmpUrl, setTmpUrl] = useState('')
    const { t } = useTranslation()
    const beforeUpload = (file) => {
        console.log(file)
        if (file.type.indexOf('image') === -1) {
            messageApi.error(t('上传格式不正确'))
            return false
        }
        if (file.size / 1024 > 1024) {
            messageApi.error(t('上传LOGO大小超过1M'))
            return false
        }
        return false

    }
    const handleChange = (file) => {
        // 上传图片
        setLoading(true)
        uploadFile(file.file).then(p => {
            const url = getIpfsUrl(p.path)
            setImageUrl(url)
            setLoading(false)
            props.ok && props.ok(url)
        }).catch(e => {
            messageApi.error(t('上传失败'))
            setLoading(false)
        })

    }

    const uploadButton = (
        <div style={{ border: 0, background: 'none' }} >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}> {t('上传')} </div>
            <div>
                <Button onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    // 输入链接
                    setShowUploadUrlDialog(true)
                }} type="link" style={{ fontSize: '12px' }}>
                    {t('输入链接')}
                </Button>

            </div>
        </div>
    );

    return <>
        {contextHolder}
        <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            style={{ overflow: 'hidden' }}
        >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
        <Modal
            title={t('输入远程图片链接')} open={showUploadUrlDialog} onOk={() => {
                if (!tmpUrl || tmpUrl.indexOf('http') === -1) {
                    messageApi.error(t('请输入正确的链接'))
                    return false
                }
                const url = tmpUrl
                setImageUrl(url)
                setShowUploadUrlDialog(false)
                props.ok && props.ok(url)
            }} onCancel={() => {
                setShowUploadUrlDialog(false)
            }}
        >

            <Form>
                <Form.Item label={t('图片链接')}>
                    <Input value={tmpUrl} placeholder="https://" onChange={e => {
                        setTmpUrl(e.target.value)
                    }} />
                    <div style={{ fontSize: '12px', marginTop: '6px' }}>
                        {t('可以使用')} <a href="https://imgse.com/"> {t('第三方图床')} </a> {t('上传图片')}, {t('然后将图片链接粘贴到此处')}
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default UploadImage
