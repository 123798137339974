import { useEffect, useState } from 'react';
import { Form, Radio, Input, Button, message, Modal, Progress, Alert } from 'antd'
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons'
import { getKeypairFromString, getNetworkConnection, getShortAddress, getSolanaProvider, isValidSolanaAddress } from '../utils/tool';
import { PublicKey, Transaction, Keypair, LAMPORTS_PER_SOL, SystemProgram, sendAndConfirmTransaction, ComputeBudgetProgram } from '@solana/web3.js'
import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAssociatedTokenAddress, getOrCreateAssociatedTokenAccount } from '@solana/spl-token'
import { useSelector } from 'react-redux';

function TransferPage() {
    const { t } = useTranslation()
    const [currentNetwork, setCurrentNetwork] = useState('mainnet-beta')
    const [tokenAddress, setTokenAddress] = useState('') // 代币地址
    const [mintInfo, setMintInfo] = useState({ // 代币信息
        decimals: '',
        uiAmount: '',
        balance: ''
    })
    const [messageApi, contextHolder] = message.useMessage()
    const [transferAmountType, setTransferAmountType] = useState('same') // 转账数量类型 same: 相同数量, diff: 不同数量
    const [getMintInfoLoading, setGetMintInfoLoading] = useState(false)
    const [transferAddressArea, setTransferAddressArea] = useState('') // 转账地址列表, 可能包含了地址和数量
    const [transferAmount, setTransferAmount] = useState('') // 转账数量
    const [sourceWalletPrivateKey, setSourceWalletPrivateKey] = useState('') // 源钱包私钥

    const [signType, setSignType] = useState('wallet') // 签名方式 wallet: 使用当前连接钱包, privateKey: 使用私钥
    const [tokenType, setTokenType] = useState('sol') // 代币类型 sol: SOL, spl: SPL代币

    const [showSubmitDialog, setShowSubmitDialog] = useState(false) // 展示提交转账信息
    const [submitLoading, setSubmitLoading] = useState(false) // 提交转账loading
    const [txIndex, setTxIndex] = useState(0) // 当前正在进行第几笔转账
    const [txTotal, setTxTotal] = useState(0) // 总共需要转账多少笔
    const [currentAction, setCurrentAction] = useState('') // 当前正在进行的操作
    const [currentPercent, setCurrentPercent] = useState(0) // 当前进度
    const [submitStatus, setSubmitStatus] = useState(false) // 提交状态
    const [submitList, setSubmitList] = useState([]) // 提交列表
    const [errMsg, setErrMsg] = useState('') // 错误信息

    const countPerTx = 10 // 每笔交易里放多少个地址

    const walletAddress = useSelector(state => state.network.wallet)

    useEffect(() => {
        // fetchData()
    }, [])
    // const fetchData = () => {
    //     let list = []
    //     for (let i = 0; i < 30; i++) {
    //         const m = Keypair.generate()
    //         list.push(m.publicKey.toString())
    //     }
    //     setTransferAddressArea(list.join('\n'))
    // }


    const getMintTokenInfo = async (address) => {
        if (!address) return
        if (signType === 'wallet' && !walletAddress) return
        if (signType === 'privateKey' && !sourceWalletPrivateKey) return
        setGetMintInfoLoading(true)

        try {
            let currentWallet;
            if (signType === 'privateKey') {
                const pk = getKeypairFromString(sourceWalletPrivateKey)
                currentWallet = pk.publicKey.toString()
            } else {
                currentWallet = walletAddress
            }
            const mintPublicKey = new PublicKey(address)
            const connection = getNetworkConnection(currentNetwork)
            const info = await connection.getTokenSupply(mintPublicKey)
            const { amount, decimals, uiAmount } = info.value

            const ataWallet = await getAssociatedTokenAddress(mintPublicKey, new PublicKey(currentWallet))
            const balance = await connection.getTokenAccountBalance(ataWallet)
            const b = balance.value.uiAmountString

            setMintInfo({
                amount,
                decimals,
                uiAmount,
                balance: b
            })
        } catch (e) {
            messageApi.error(e.message)
        }


        setGetMintInfoLoading(false)
    }

    useEffect(() => {
        if (isValidSolanaAddress(tokenAddress)) {
            // 获取代币信息
            getMintTokenInfo(tokenAddress)

        }
    }, [currentNetwork, tokenAddress, walletAddress, sourceWalletPrivateKey, signType])

    const submit = () => {
        // 检查代币合约是否正确
        if (tokenType === 'spl' && !isValidSolanaAddress(tokenAddress)) {
            messageApi.error(t('代币合约地址不正确'))
            return
        }
        // 检查代币信息是否完整
        if (tokenType === 'spl' && (mintInfo.decimals === '' || mintInfo.uiAmount === '' || mintInfo.balance === '')) {
            messageApi.error(t('代币信息不完整'))
            return
        }

        if (signType === 'privateKey') {
            // 检查私钥是否正确
            try {
                const pk = getKeypairFromString(sourceWalletPrivateKey)
            } catch (e) {
                console.log(e)
                messageApi.error(t('请输入正确的钱包私钥'))
                return
            }
        }

        // 检查是否输入了转账类型和数量
        if (transferAmountType === 'same') {
            if (!transferAmount) {
                messageApi.error(t('请输入转账数量'))
                return
            }
        }
        // 检查转账地址和数量是否正确
        const addressList = transferAddressArea.split('\n').filter(i => i)
        if (addressList.length === 0) {
            messageApi.error(t('请输入转账地址'))
            return
        }

        let list = []
        let err = ''
        for (let i = 0; i < addressList.length; i++) {
            const arr = addressList[i].split(',')
            if (arr.length === 0) {
                err = t('第1行数据转账地址数据不正确').replace('{i}', i + 1)
                break
            }
            let am = 0
            if (transferAmountType === 'diff') { // 不同数量的话这里必须要填数量
                if (arr.length !== 2) {
                    err = t('第3行地址缺少转账数量').replace('{i}', i + 1)
                    break
                }
                am = parseFloat(arr[1])
            } else {
                am = transferAmount
            }
            if (!isValidSolanaAddress(arr[0])) {
                err = t('第4行地址不正确').replace('{i}', i + 1)
                break
            }
            list.push({
                address: arr[0],
                amount: am
            })
        }

        if (err !== '') {
            messageApi.error(err)
            return
        }
        setSubmitList(list)
        // 切分地址列表, 展示转账信息, 并开始转账
        submitLogic(list, 0)

    }

    const submitLogic = async (list, ind) => {
        const connection = getNetworkConnection(currentNetwork)
        const provider = getSolanaProvider(currentNetwork)
        let currentWallet = provider.wallet
        if (signType === 'privateKey') {
            currentWallet = getKeypairFromString(sourceWalletPrivateKey)
        }

        // 切分地址列表
        setErrMsg('')
        let txList = []
        let tx = []
        for (let i = 0; i < list.length; i++) {
            tx.push(list[i])
            if (tx.length === countPerTx) {
                txList.push(tx)
                tx = []
            }
        }
        if (tx.length > 0) {
            txList.push(tx)
        }
        // 展示转账信息
        // 开始转账
        let sourceAccount;
        if (tokenType === 'sol') {
            sourceAccount = new PublicKey(currentWallet.publicKey.toString())
        } else {
            sourceAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                new PublicKey(currentWallet.publicKey.toString()),
                new PublicKey(tokenAddress),
                new PublicKey(currentWallet.publicKey.toString()),
            );
        }

        // 正在进行第一笔转账 正在获取 xxxx 的 ata地址 正在提交交易
        setShowSubmitDialog(true)
        setSubmitLoading(true)
        setSubmitStatus(false)

        const total = txList.length + list.length // 需要的转账次数+要获取的ata地址次数
        let cPercent = 0
        if (ind > 0) {
            cPercent = ind * (countPerTx + 1)
        }
        setTxTotal(txList.length)
        let throwErr = false
        for (let i = ind; i < txList.length; i++) {
            setTxIndex(i)
            cPercent += 1
            setCurrentPercent(cPercent / total * 100)
            setCurrentAction(t('正在处理第1笔转账').replace('{i}', i + 1))
            const txs = txList[i]
            // 开始转账
            const txInstructions = []


            try {
                for (let j = 0; j < txs.length; j++) {
                    const item = txs[j]
                    // 目标的ata地址
                    let destinationAccount;
                    if (tokenType === 'spl') {
                        destinationAccount = await getAssociatedTokenAddress(new PublicKey(tokenAddress), new PublicKey(item.address))
                    }


                    // 如果ata地址不存在, 则进行创建
                    cPercent += 1
                    setCurrentPercent(cPercent / total * 100)
                    setCurrentAction(t('正在获取') + getShortAddress(item.address) + t('的ata地址'))
                    if (tokenType === 'spl' && !(await connection.getAccountInfo(destinationAccount))) {
                        txInstructions.push(
                            createAssociatedTokenAccountInstruction(
                                new PublicKey(currentWallet.publicKey.toString()),
                                destinationAccount,
                                new PublicKey(item.address),
                                new PublicKey(tokenAddress)
                            )
                        )
                    }
                    if (tokenType === 'spl') {
                        txInstructions.push(
                            createTransferInstruction(
                                sourceAccount.address,
                                destinationAccount,
                                new PublicKey(currentWallet.publicKey.toString()),
                                parseInt(parseFloat(item.amount) * Math.pow(10, parseInt(mintInfo.decimals)))
                            )
                        )
                    } else {
                        txInstructions.push(
                            SystemProgram.transfer({
                                fromPubkey: sourceAccount,
                                toPubkey: new PublicKey(item.address),
                                lamports: item.amount * LAMPORTS_PER_SOL,
                            })
                        )

                    }

                }

                // 添加一个小费交易
                txInstructions.push(
                    ComputeBudgetProgram.setComputeUnitPrice({
                        microLamports: 50000
                    })
                )
                txInstructions.push(
                    ComputeBudgetProgram.setComputeUnitLimit({
                        units: 1000000
                    })
                )


                const tx = new Transaction().add(...txInstructions)
                setCurrentAction(t('正在提交第x笔交易').replace('{i}', i + 1))
                const latestBlockHash = await connection.getLatestBlockhash('confirmed');
                tx.recentBlockhash = await latestBlockHash.blockhash;
                let txid;
                if (signType === 'privateKey') {
                    txid = await sendAndConfirmTransaction(
                        connection,
                        tx,
                        [currentWallet]
                    )
                } else {
                    txid = await provider.sendAndConfirm(tx);
                }


                const confirmation = await connection.confirmTransaction({
                    signature: txid,
                    blockhash: latestBlockHash.blockhash,
                    lastValidBlockHeight: latestBlockHash.lastValidBlockHeight
                });
                console.log('交易哈希 ', txid)
                // 交易成功
            } catch (e) {
                console.log(e)
                handleErr(e)
                throwErr = true
                break
            }



        }
        // 没有抛出异常才正常走到这里
        if (throwErr === false) {
            messageApi.success(t('转账成功'))
            setSubmitStatus(true)
            setCurrentAction('')
        }

        setSubmitLoading(false)

    }

    const handleErr = (e) => {
        setCurrentAction('')
        if (e.message) {
            setErrMsg(e.message)
        } else {
            setErrMsg(JSON.stringify(e))
        }

    }

    return (
        <div>
            {contextHolder}
            {/* 1. 选择网络
            2. 输入代币合约
            3. 转移相同数量代币/不同数量代币
            4. 输入接收地址列表+数量 (支持excel导入导出)
            5. 确认转账(多笔交易进行切分) */}
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} >
                <Form.Item label={t('选择网络')}>
                    <Radio.Group onChange={e => {
                        setCurrentNetwork(e.target.value)
                    }} value={currentNetwork}>
                        <Radio value={'mainnet-beta'}>{t('Solana主网')}</Radio>
                        <Radio value={'devnet'}>{t('Solana开发网')}</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label={t('签名方式')}>
                    <Radio.Group onChange={e => {
                        setSignType(e.target.value)
                    }} value={signType}>
                        <Radio value={'wallet'}>{t('使用当前连接钱包')}</Radio>
                        <Radio value={'privateKey'}>{t('使用私钥')}</Radio>
                    </Radio.Group>
                </Form.Item>
                {signType === 'privateKey' && <Form.Item label={t('输入私钥')}>
                    <Input value={sourceWalletPrivateKey} onChange={e => {
                        setSourceWalletPrivateKey(e.target.value)
                    }} placeholder={t('请输入钱包私钥')} />
                </Form.Item>}

                <Form.Item label={t('代币类型')}>
                    <Radio.Group onChange={e => {
                        setTokenType(e.target.value)
                    }} value={tokenType}>
                        <Radio value={'sol'}>{t('SOL')}</Radio>
                        <Radio value={'spl'}>{t('SPL代币')}</Radio>
                    </Radio.Group>
                </Form.Item>

                {tokenType === 'spl' && <Form.Item label={t('输入代币合约')}>
                    <Input value={tokenAddress} onChange={e => {
                        setTokenAddress(e.target.value)
                    }} />
                </Form.Item>}


                {getMintInfoLoading ? <Form.Item wrapperCol={{ offset: 4, span: 14 }} label="">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <LoadingOutlined />
                        <div style={{ marginTop: '10px' }}>
                            {t('正在获取代币信息...')}
                        </div>
                    </div>
                </Form.Item> : <>
                    {mintInfo.decimals > 0 && <Form.Item label={t('精度')}>
                        <Input value={mintInfo.decimals} disabled />
                    </Form.Item>}
                    {mintInfo.uiAmount > 0 && <Form.Item label={t('总量')}>
                        <Input value={mintInfo.uiAmount} disabled />
                    </Form.Item>}
                    {mintInfo.balance > 0 && <Form.Item label={t('可用余额')}>
                        <Input value={mintInfo.balance} disabled />
                    </Form.Item>}
                </>}

                <Form.Item label={t('转账数量')}>

                    <Radio.Group onChange={e => {
                        setTransferAmountType(e.target.value)
                    }} value={transferAmountType}>
                        <Radio value={'same'}>{t('相同数量')}</Radio>
                        <Radio value={'diff'}>{t('不同数量')}</Radio>
                    </Radio.Group>

                    {transferAmountType === 'same' && <div style={{ marginTop: '10px' }}>
                        <Input value={transferAmount} onChange={e => {
                            setTransferAmount(e.target.value)
                        }} placeholder='请输入要给每个地址转账的代币数量' />
                    </div>}


                </Form.Item>
                <Form.Item label={t('转账地址')}>
                    <Input.TextArea rows={10} placeholder={transferAmountType === 'same' ? t('每行一个地址1') : t('每行一个地址2')} value={transferAddressArea} onChange={e => {
                        setTransferAddressArea(e.target.value)
                    }} />

                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
                    <Button loading={submitLoading} onClick={submit} type='primary'> {t('确认转账')} </Button>
                </Form.Item>

            </Form>

            <Modal title={t('提交交易')} open={showSubmitDialog} onOk={() => {
                setShowSubmitDialog(false)
            }} closable={false} onCancel={() => {
                setShowSubmitDialog(false)
            }} footer={null} >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >

                    <Progress type="circle" percent={submitStatus ? 100 : (txTotal === 0 ? 0 : (parseInt(txIndex / txTotal * 100)))} />
                    <div style={{ marginTop: '10px' }}>
                        {submitStatus ? <>
                            {t('交易完成')}
                        </> : <>
                            <LoadingOutlined /> &nbsp;    {t('正在提交第1笔交易').replace('{i}', txIndex + 1)}({txIndex + 1}/{txTotal})
                        </>}
                    </div>
                    <div style={{ width: '100%' }}>
                        <Progress percent={submitStatus ? 100 : parseFloat(currentPercent).toFixed(1)} />
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: '300', marginTop: '10px', marginBottom: '10px' }}>
                        {currentAction}
                    </div>
                    {errMsg && <div style={{ width: '100%', marginTop: '0px', marginBottom: '10px' }}>
                        <Alert
                            style={{ width: '100%' }}
                            message={t('错误')}
                            description={errMsg}
                            type="error"
                            closable={false}
                        />
                    </div>}
                    <div>
                        {errMsg && <Button onClick={() => {
                            submitLogic(submitList, txIndex)
                        }} style={{ marginRight: '10px' }} type='primary'> {t('重试')} </Button>}
                        <Button onClick={e => {
                            setShowSubmitDialog(false)
                        }} type='primary' danger> {t('关闭')} </Button>
                    </div>



                </div>
            </Modal>
        </div>
    );
}

export default TransferPage;