
import { PublicKey, Connection, clusterApiUrl, Keypair } from '@solana/web3.js'
import { AnchorProvider } from '@project-serum/anchor'
import bs58 from 'bs58'

export const getShortAddrss = (address) => {
    if (address) {
        return address.substr(0, 6) + '...' + address.substr(-4)
    }
    return ''
}


export const isValidSolanaAddress = (address) => {
    try {
        // 尝试创建一个PublicKey实例，如果地址无效，将抛出错误
        new PublicKey(address);
        return true;
    } catch (error) {
        // 如果出现错误，地址无效
        return false;
    }
}

export const getNetworkConnection = (currentNetwork) => {
    let url = 'https://dry-sparkling-road.solana-mainnet.quiknode.pro/9155fd5ad8287d2247fb4cde578fbf9ce2c42f1b/'
    if (currentNetwork === 'devnet') {
        url = clusterApiUrl(currentNetwork)
    }
    const connection = new Connection(url);
    return connection
}

export const getSolanaProvider = (currentNetwork) => {
    const connection = getNetworkConnection(currentNetwork)
    const provider = new AnchorProvider(connection, window.solana, {
        preflightCommitment: 'confirmed',
        commitment: 'confirmed',
    })
    return provider
}


export const getShortAddress = (address) => {
    if (address) {
        return address.substr(0, 6) + '...' + address.substr(-4)
    }
    return ''

}

export const getKeypairFromString = (secretKeyString) => {
    let decodedSecretKey
    try {
        // Keypair.base58ToSecretKey(secretKeyString);
        decodedSecretKey = bs58.decode(secretKeyString);
    } catch (throwObject) {
        throw new Error("Invalid secret key! See README.md");
    }
    return Keypair.fromSecretKey(decodedSecretKey);
};