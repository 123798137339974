import { create } from 'ipfs-http-client'
import axios from 'axios'
// const ipfsUrl = 'https://ipfs.token-monitor.com'
const ipfsUrl = 'https://ipfs.fatsale.org'
const ipfsGatewayUrl = 'https://ipfs.gateway.fatsale.org'
// const picBedUrl = 'http://127.0.0.1:8083'
const picBedUrl = 'https://oss.fatsale.org'

// 上传文件
export const uploadFile = async (file) => {
    const client = await create(ipfsUrl)
    const added = await client.add(file)
    return added
}

// 保存文字
export const uploadText = async (text) => {
    const client = await create(ipfsUrl)
    const added = await client.add(text)
    return added
}

export const uploadFolder = (dir, jsonList) => {
    // 创建并返回文件夹的ipfs
    // axios
    const url = `${picBedUrl}/uploadFolderToIpfs`
    return new Promise((resolve, reject) => {
        axios({
            url,
            method: 'post',
            data: {
                dir,
                list: jsonList
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
    })

}

export const getIpfsUrl = (key) => {
    return `${ipfsGatewayUrl}/ipfs/${key}`
}

export const convertIpfsKeyToProtocolUrl = (key) => {
    return `ipfs://${key}`
}

// 获取保存的文字
export const getIpfsText = async (key) => {
    const url = getIpfsUrl(key)
    const resp = await fetch(url)
    let text = await resp.text()
    if (isJson(text)) {
        text = JSON.stringify(traverseAndReplace(JSON.parse(text)))
    }
    return text
}

const traverseAndReplace = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object') {
                // 如果当前属性是对象，递归遍历子属性
                traverseAndReplace(obj[key])
            } else if (typeof obj[key] === 'string') {
                // 如果当前属性是字符串，替换指定的子串
                obj[key] = obj[key].replace('https://ipfs.gateway.fatsale.finance', ipfsGatewayUrl)
                obj[key] = obj[key].replace('https://ipfs.gateway.token-monitor.com', ipfsGatewayUrl)
            }
        }
    }
    return obj
}

const isJson = (str) => {
    if (typeof str !== 'string') {
        return false
    }

    try {
        JSON.parse(str)
        return true
    } catch (e) {
        return false
    }
}


export const uploadFileToBed = (file, fileName, cb, success, fail) => {
    // 上传文件到图床中
    // 文件切片, 构造请求, 遍历上传
    // 上传完毕后, 返回图片地址
    const chunkSize = 1024 * 1024 / 2 // 每个切片的大小
    const totalChunks = Math.ceil(file.size / chunkSize) // 总切片数
    let currentChunk = 0

    // 当前切片号 切片大小  总切片数  总大小  文件
    const uploadNextChunk = () => {
        const start = currentChunk * chunkSize
        const end = Math.min(start + chunkSize, file.size)
        const chunk = file.slice(start, end) // 获取当前切片
        const formData = new FormData()
        formData.append('file', chunk)
        formData.append('chunkIndex', currentChunk.toString())
        formData.append('chunkTotal', totalChunks.toString())
        // fileName是 合约地址_时间戳 / 序号.png 
        formData.append('fileName', fileName)


        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            xhr.open('POST', `${picBedUrl}/upload`, true)
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText)
                    console.log(response)
                    // 处理上传成功的响应

                    cb({
                        total: file.size,
                        current: end
                    })
                    currentChunk++

                    if (currentChunk < totalChunks) {

                        uploadNextChunk()
                    } else {
                        console.log('切片上传完成', response)
                        // 所有切片上传完成
                        success({
                            url: response.path ? `${picBedUrl}/files/${response.path}` : ''
                        })
                    }
                } else {
                    console.error('上传失败')
                    fail('上传失败')
                }
            }
            xhr.onerror = function () {
                console.error('网络错误')
                // 处理网络错误的情况
                fail('网络错误')
            }
            xhr.send(formData)
        })
    }

    uploadNextChunk()
}
